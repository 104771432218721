/* ProductPageV2.css */

.product-page {
    font-family: 'Arial', sans-serif;
    color: #333;
    background: linear-gradient(to right, #ece9e6, #ffffff);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    animation: fadeIn 1s ease-out;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

/* Header with 3D Greyish Glassmorphism */
.page-header {
    text-align: center;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    color: #333;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 0 0 16px 16px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 10;
    transition: background 0.5s ease;
}

.page-header:hover {
    background: rgba(255, 255, 255, 0.5);
}

/* Main Content */
.page-main {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 2rem;
    animation: slideUp 1s ease-out;
}

@keyframes slideUp {
    from { transform: translateY(50px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

/* Payment Card */
.payment-card {
    background: #ffffff;
    border-radius: 16px;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
    padding: 2rem;
    width: 100%;
    max-width: 400px;
    text-align: center;
    border: 1px solid #e0e0e0;
    transform: translateY(-10px);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
}

.payment-card:hover {
    transform: translateY(-20px);
    box-shadow: 0 12px 30px rgba(0, 0, 0, 0.2);
}

.payment-card h2 {
    margin-bottom: 1.5rem;
    color: #4A5568;
    font-size: 1.6rem;
    font-weight: bold;
    animation: fadeIn 1.5s ease-out;
}

/* Form Group */
.form-group {
    margin-bottom: 1.5rem;
    text-align: left;
    animation: fadeIn 2s ease-out;
}

.payment-label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    color: #555;
    transition: color 0.3s ease;
}

.payment-label:hover {
    color: #4A5568;
}

/* Input */
.payment-input {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    box-sizing: border-box;
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
    animation: fadeIn 2.5s ease-out;
}

.payment-input:focus {
    border-color: #4A5568;
    box-shadow: 0 0 8px rgba(74, 85, 104, 0.5);
}

/* Button with Creative Animation */
.payment-button {
    width: 100%;
    padding: 0.9rem;
    border: none;
    border-radius: 8px;
    background: linear-gradient(to right, #4A5568, #2D3748);
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background 0.3s, transform 0.2s, box-shadow 0.3s;
    position: relative;
    overflow: hidden;
    animation: fadeIn 3s ease-out;
}

.payment-button:hover {
    background: linear-gradient(to right, #2D3748, #1A202C);
    transform: translateY(-3px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.payment-button.loading::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 24px;
    height: 24px;
    border: 3px solid transparent;
    border-top: 3px solid #fff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    transform: translate(-50%, -50%);
}

.payment-button.success::after {
    content: '✔';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
    color: #fff;
}

/* Spinner Animation */
@keyframes spin {
    from { transform: translate(-50%, -50%) rotate(0deg); }
    to { transform: translate(-50%, -50%) rotate(360deg); }
}

/* Footer with 3D Greyish Glassmorphism */
.page-footer {
    text-align: center;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(30px);
    -webkit-backdrop-filter: blur(30px);
    color: #333;
    font-size: 0.9rem;
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 16px 16px 0 0;
    box-shadow: 0 -8px 20px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 10;
    transition: background 0.5s ease;
    animation: fadeIn 4s ease-out;
}

.page-footer:hover {
    background: rgba(255, 255, 255, 0.5);
}
